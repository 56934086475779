<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h1 class='display-2'>Konfigurasi Aktivitas</h1>
          </template>
          <div class="d-flex flex-row pa-3">
            <div style="max-width:400px" class="d-flex justify-start pt-2">
              <v-autocomplete v-model="selectJenis" :items="jenisJabatan" label="Pilih Jenis Jabatan ASN" item-text="v" item-value="k" hide-details></v-autocomplete>
            </div>
            <v-spacer></v-spacer>
            <div style="max-width:400px" class="mx-5">
              <v-text-field v-model="search" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
            </div>
            <div style="max-width:400px" class="d-flex justify-end pt-2">
              <v-btn medium color="primary" @click="editAktivitas()"><v-icon small>mdi-clock</v-icon> &nbsp;Aktivitas Tambahan</v-btn>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[5,10,15]}"
            class="pegawai-table"
            :loading="loading"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.skpd="{item}">
              <span v-if="item.kolok == 'ALL'">{{ item.kolok }}</span>
              <span v-else>{{ item.nama_skpd }}</span>
            </template>
            <template v-slot:item.aksi="{item}" style="border:4px solid black">
              <div class="d-flex flex-column">
                <v-btn @click="edit(item)" x-small class="warning ma-1" dark><v-icon small>mdi-pencil</v-icon> Edit Item</v-btn>
              </div>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import KonfigurasiAktivitasService from '@/services/KonfigurasiAktivitasService'
import store from '@/store'
import _g from '../../global'

export default {
  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {text:'Nama Jabatan', value:'nama_jabatan'},
        {text:'SKPD', value:'skpd'},
        {text:'Kode Jabatan', value:'kojab'},
        {text:'Jumlah Aktivitas', value:'jumlah'},
        {text:'Aksi', value:'aksi'},
      ],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableDetail:false,
        enableCreate:false,
      },
      rowPerPage:0,
      page:1,
      totalKeseluruhan:0,
      selectJenis: '',
      jenisJabatan:[{
        k:'',v:'-- Pilih Jabatan --'
      },{
        k:'S',v:'Struktural'
      },{
        k:'U',v:'Fungsional Umum'
      },{
        k:'T',v:'Fungsional Tertentu'
      },{
        k:'K',v:'Tenaga Kesehatan'
      }],
      search: '',
      dialogLihatSKP: false,
      dataSKP: [],
      loadingLihatSKP: false,
      nilai_total_capaian: null,
      selected: {},
      tahun: null,
      bulanList:[
        { b:'Januari',k:1},
        { b:'Febuari',k:2},
        { b:'Maret',k:3},
        { b:'April', k:4},
        { b:'Mei', k:5},
        { b:'Juni', k:6},
        { b:'Juli', k:7},
        { b:'Agustus', k:8},
        { b:'September', k:9},
        { b:'Oktober', k:10},
        { b:'November', k:11},
        { b:'Desember', k:12},
      ],
      selectBulan:null
    }
  },

  computed: {
    baseUrl(){
      return process.env.VUE_APP_API_URL + '/api'
    },
    currentYear(){
      return store.state.user.current.year
    }
  },

  watch:{
    page(val){
      this.tableValue.page = val
      this.updateTable(this.tableValue)
    }
  },

  mounted(){
    this.$watch(vm => [vm.selectJenis, vm.rowPerPage, vm.search, vm.selectSKPD, vm.selectBulan], () => {
      this.page = 1
      this.updateTable({jab:this.selectJenis, itemsPerPage:this.rowPerPage, bulan:this.selectBulan, kode_skpd:this.selectSKPD, search:this.search, page:this.page})
    })
  },

  created(){
    var d = new Date()
    var m = d.getMonth()+1
    this.selectBulan = m
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
    this.tahun = this.$store.getters["user/data"].tahun
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){
      this.loading = true
      this.tableValue = val ? val: this.tableValue
			if(this.tableValue.jab == "S"){
				this.tableValue.jab = 2
			}else if(this.tableValue.jab == "T"){
				this.tableValue.jab = 3
			}else if(this.tableValue.jab == "U"){
				this.tableValue.jab = 4
			}else if(this.tableValue.jab == "K"){
				this.tableValue.jab = 5
			}else{
				this.loading = false
				this.items = []
				return false
			}
      this.items = []

      KonfigurasiAktivitasService.getData({
        jab:this.tableValue.jab,
        search:this.tableValue.search,
        row_per_page:this.tableValue.itemsPerPage,
        page:this.tableValue.page
      })
      .then((response)=>{
        this.items = response.data.data
        this.totalKeseluruhan = response.data.total_keseluruhan
        console.log(this.items)
        let res = response.data
        if (res.status == 200){
          store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
      }).finally(()=>{
        this.loading = false
      })
    },

    edit(item){
      this.$router.push({
        path: '/admin/konfigurasi-aktivitas/edit-aktivitas',
        component:() => import('@/views/KonfigurasiAktivitas/EditAktivitas.vue'),
        params: item,
        name: 'Konfigurasi Aktivitas Utama'
      })
    },
    
    editAktivitas(){
      this.$router.push({
        path: '/admin/konfigurasi-aktivitas/edit-aktivitas-tambahan',
        component:() => import('@/views/KonfigurasiAktivitas/EditAktivitasTambahan.vue')
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.pegawai-table{
  tr,th,td {
    border: 1px solid #ddd;
  }
  thead tr th{
    color:white;
  }
}
</style>
